export default {
  async fetchMessagesUsingThreadId(_, payload) {
    const response = await fetch(`${process.env.VUE_APP_AXIOS_URI}/eddie/smart-messages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      },
      body: JSON.stringify(payload),
    });
    return response.json();
  },

  async streamResponseWithAssistantAndThreadId(_, {
    imageInput, userPrompt, threadId, assistantId, callback,
  }) {
    try {
      const formData = new FormData();

      // Append the image file to formData. Replace `imageFile` with the actual file object.
      if (imageInput) {
        formData.append('openai_file_upload', imageInput);
      }

      // Append other parts of the data as individual form fields
      formData.append('userPrompt', userPrompt);
      formData.append('assistantId', assistantId);
      formData.append('threadId', threadId);
      const response = await fetch(`${process.env.VUE_APP_AXIOS_URI}/eddie/smart-run`, {
        method: 'POST',
        headers: {
          // 'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
        body: formData,
      });

      if (!response.body) {
        throw new Error('ReadableStream not yet supported in this browser.');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      let result = '';
      const isRun = true;
      while (isRun) {
        // eslint-disable-next-line no-await-in-loop
        const { done, value } = await reader.read();
        if (done) break;
        result += decoder.decode(value, { stream: true });
        callback(decoder.decode(value, { stream: true }));
      }

      console.log(result);
      console.log('Stream ended.');
      return result;
    } catch (error) {
      console.error('Error:', error);
    }

    return '';
  },
};
